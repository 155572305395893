import React, { useState } from "react";

import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import { useLicense } from "../contexts/LicenseContext";
import { Button } from "baseui/button";
import { H4, ParagraphSmall, Paragraph2, LabelXSmall } from "baseui/typography";
import { Notification } from "baseui/notification";
import { Grid, Cell } from "baseui/layout-grid";
import { Block } from "baseui/block";
import { ArrowRight, Check } from "baseui/icon";
import { Card, StyledBody, StyledAction } from "baseui/card";
import { Tag, KIND } from "baseui/tag";
import { OfficialLinks } from "../components/OfficialLinks";
import { FlexGrid, FlexGridItem } from "baseui/flex-grid";

export function InvalidLicenseBox({ onSuccess }) {
  const [value, setValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { validate, valid } = useLicense();

  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);
    const isValid = await validate(value);
    if (isValid) {
      onSuccess();
    }
    setIsLoading(false);
  }

  const benefits = [
    "Unlimited exports",
    "Unlimited boards",
    "Unlimited cards export",
    "Export to Excel, CSV or JSON",
    "Advanced filters",
    "Split cards into Excel sheets by member or list",
  ];

  return (
    <Grid gridMargins={0}>
      <Cell span={[6, 4]}>
        <H4 marginTop="12px" marginBottom={0}>
          {process.env.REACT_APP_PRODUCT_NAME}
        </H4>
        <Block>
          <ParagraphSmall>
            To use Smart Export you need a valid license key. If you do have one
            enter it below.
          </ParagraphSmall>
          {!valid && (
            <Notification
              overrides={{
                Body: {
                  style: {
                    boxSizing: "border-box",
                    width: "100%",
                  },
                },
              }}
              kind="negative"
            >
              The license key is invalid or the license has expired.
            </Notification>
          )}
          <form onSubmit={handleSubmit}>
            <FormControl>
              <Input
                value={value}
                required
                onChange={(e) => setValue(e.target.value)}
                placeholder="Enter your license key"
              />
            </FormControl>
            <Button isLoading={isLoading} type="submit">
              Apply
            </Button>
          </form>
          <OfficialLinks />
        </Block>
      </Cell>
      <Cell span={[6, 4]}>
        <Block paddingTop="60px">
          <Card
            overrides={{
              Action: { style: { paddingTop: "48px" } },
            }}
          >
            <StyledBody align="center">
              <H4 marginTop={0} marginBottom="8px">
                Buy new license
              </H4>
              <Tag
                closeable={false}
                variant="solid"
                kind={KIND.blue}
                size="medium"
                overrides={{
                  Root: { style: { marginBottom: "24px" } },
                  Text: { style: { maxWidth: "auto" } },
                }}
              >
                $6/month or $18/year per user
              </Tag>
              {benefits.map((text, index) => (
                <Paragraph2 marginTop="4px" marginBottom={0} key={index}>
                  <Check
                    size={24}
                    overrides={{
                      Svg: { style: { verticalAlign: "bottom" } },
                    }}
                  />{" "}
                  {text}
                </Paragraph2>
              ))}
              <LabelXSmall marginTop="10px" marginBottom="6px">
                Trusted by 8,000+ users including professionals at
              </LabelXSmall>

              <FlexGrid
                flexGridColumnCount={3}
                flexGridColumnGap="scale200"
                flexGridRowGap="scale200"
              >
                <FlexGridItem>
                  <img
                    src="/Amazon_logo.svg"
                    alt="Amazon.com Logo"
                    height="18"
                  />
                </FlexGridItem>
                <FlexGridItem>
                  <img
                    src="/Booking.com_logo.svg"
                    alt="Booking.com Logo"
                    height="18"
                  />
                </FlexGridItem>
                <FlexGridItem>
                  <img src="/Hertz_logo.svg" alt="Hertz Logo" height="18" />
                </FlexGridItem>
              </FlexGrid>
            </StyledBody>
            <StyledAction align="center">
              <Button
                endEnhancer={() => <ArrowRight size={24} />}
                shape="pill"
                $as="a"
                href={`${process.env.REACT_APP_LICENSE_MANAGER_URL}/checkout`}
                target="_blank"
              >
                Continue purchase
              </Button>
            </StyledAction>
          </Card>
        </Block>
      </Cell>
    </Grid>
  );
}
