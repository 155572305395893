import React from "react";
import Markdown from "react-markdown";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Link,
  Font,
  Svg,
  Path,
} from "@react-pdf/renderer";
import { parseISO } from "date-fns";
import remarkGfm from "remark-gfm";
import remarkEmoji from "remark-emoji";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 20,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottom: "1px solid #ddd",
    paddingBottom: 8,
    marginBottom: 8,
    fontSize: 10,
    width: "100%",
    color: "#666",
  },
  title: {
    fontSize: 24,
    color: "#000",
    marginBottom: 8,
  },
  section: {
    padding: 8,
    border: "1px solid #ddd",
    borderRadius: 4,
    marginBottom: 8,
    flexDirection: "column",
  },
  section__title: {
    fontSize: 12,
    marginBottom: 4,
  },
  details__row: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 4,
  },
  details__row__label: {
    fontSize: 11,
    color: "#666",
  },
  details__row__value: {
    fontSize: 11,
    color: "#000",
  },
  p: {
    fontSize: 10,
    marginBottom: 4,
  },
  strong: {
    fontFamily: "Helvetica-Bold",
  },
  link: {
    color: "#0079bf",
  },
  hr: {
    borderBottom: "1px solid #ddd",
    marginBottom: 4,
  },
  list: {
    marginBottom: 4,
  },
  listItem: {
    fontSize: 10,
  },
  em: {
    fontFamily: "Helvetica-Oblique",
  },
});

Font.registerEmojiSource({
  format: "png",
  url: "https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/",
});

const markdownComponents = {
  p: ({ children }) => <Text style={styles.p}>{children}</Text>,
  strong: ({ children }) => <Text style={styles.strong}>{children}</Text>,
  a: ({ children, href }) => (
    <Link style={styles.link} src={href}>
      {children}
    </Link>
  ),
  hr: () => <View style={styles.hr}></View>,
  del: ({ children }) => (
    <Text style={{ textDecoration: "line-through" }}>{children}</Text>
  ),
  em: ({ children }) => <Text style={styles.em}>{children}</Text>,
  h1: ({ children }) => (
    <Text style={{ fontSize: 16, fontWeight: "bold", marginBottom: 4 }}>
      {children}
    </Text>
  ),
  h2: ({ children }) => (
    <Text style={{ fontSize: 14, fontWeight: "bold", marginBottom: 4 }}>
      {children}
    </Text>
  ),
  h3: ({ children }) => (
    <Text style={{ fontSize: 12, fontWeight: "bold", marginBottom: 4 }}>
      {children}
    </Text>
  ),
  h4: ({ children }) => (
    <Text style={{ fontSize: 10, fontWeight: "bold", marginBottom: 4 }}>
      {children}
    </Text>
  ),
  h5: ({ children }) => (
    <Text style={{ fontSize: 8, fontWeight: "bold", marginBottom: 4 }}>
      {children}
    </Text>
  ),
  h6: ({ children }) => (
    <Text style={{ fontSize: 6, fontWeight: "bold", marginBottom: 4 }}>
      {children}
    </Text>
  ),
  ol: ({ children }) => <View style={styles.list}>{children}</View>,
  ul: ({ children }) => <View style={styles.list}>{children}</View>,
  li: ({ children }) => <Text style={styles.listItem}>• &nbsp;{children}</Text>,
  blockquote: ({ children }) => (
    <View
      style={{ borderLeft: "2px solid #eee", paddingLeft: 4, marginBottom: 4 }}
    >
      {children}
    </View>
  ),
  code: ({ children }) => (
    <Text
      style={{
        fontFamily: "Courier",
        fontSize: 9,
        backgroundColor: "#eee",
        padding: 2,
        borderRadius: 2,
        marginBottom: 4,
      }}
    >
      {children}
    </Text>
  ),
};

// Create Document Component
export function Simple({ board, card, list }) {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Text>{board.name}</Text>
          <Text>Exported at {new Date().toLocaleString()}</Text>
        </View>
        <View style={styles.title}>
          <Text>{card.name}</Text>
          <Text style={{ fontSize: 10, color: "#666" }}>#{card.idShort}</Text>
        </View>
        <View style={styles.section}>
          <View style={styles.details__row}>
            <Text style={styles.details__row__label}>List</Text>
            <Text style={styles.details__row__value}>{list.name}</Text>
          </View>
          {card.start && (
            <View style={styles.details__row}>
              <Text style={styles.details__row__label}>Start Date</Text>
              <Text style={styles.details__row__value}>
                {parseISO(card.start).toLocaleString()}
              </Text>
            </View>
          )}
          {card.due && (
            <View style={styles.details__row}>
              <Text style={styles.details__row__label}>Due Date</Text>
              <Text style={styles.details__row__value}>
                {parseISO(card.due).toLocaleString()}
              </Text>
            </View>
          )}
          <View style={styles.details__row}>
            <Text style={styles.details__row__label}>Labels</Text>
            <Text style={styles.details__row__value}>
              {card.labels.map((label) => label.name).join(", ")}
            </Text>
          </View>
          <View style={styles.details__row}>
            <Text style={styles.details__row__label}>Members</Text>
            <Text style={styles.details__row__value}>
              {card.members.map((member) => member.fullName).join(", ")}
            </Text>
          </View>
          {board.customFields &&
            board.customFields.map((customField) => {
              const customFieldItem = card.customFieldItems.find(
                (customFieldItem) =>
                  customFieldItem.idCustomField === customField.id
              );

              if (!customFieldItem) {
                return null;
              }

              let value;

              if (customFieldItem.value) {
                if (customField.type === "number") {
                  value = customFieldItem.value["number"];
                } else if (customField.type === "date") {
                  const dateValue = customFieldItem.value["date"];
                  let formattedValue = dateValue;

                  try {
                    formattedValue = parseISO(dateValue).toLocaleString();
                  } catch (error) {
                    console.error(error);
                  }

                  value = formattedValue;
                } else {
                  value =
                    customFieldItem.value[
                      customField.type === "checkbox" ? "checked" : "text"
                    ];
                }
              } else if (customFieldItem.idValue) {
                const option = customField.options.find(
                  (option) => option.id === customFieldItem.idValue
                );

                if (option) {
                  const optionValue = Object.values(option.value)[0];

                  value = optionValue;
                }
              }

              return (
                <View key={customField.id} style={styles.details__row}>
                  <Text style={styles.details__row__label}>
                    {customField.name}
                  </Text>
                  <Text style={styles.details__row__value}>{value}</Text>
                </View>
              );
            })}
        </View>
        {card.desc && (
          <>
            <Text style={styles.section__title}>Description</Text>
            <View style={styles.section}>
              <Markdown
                remarkPlugins={[
                  [remarkGfm, { singleTilde: false }],
                  remarkEmoji,
                ]}
                components={markdownComponents}
                disallowedElements={["img"]}
                unwrapDisallowed={true}
              >
                {card.desc}
              </Markdown>
            </View>
          </>
        )}
        {card.checklists && card.checklists.length > 0 && (
          <>
            <Text style={styles.section__title}>Checklists</Text>
            {card.checklists.map((checklist) => (
              <View style={styles.section} key={checklist.id}>
                <View>
                  <Text
                    style={{
                      fontSize: 11,
                      color: "#666",
                      marginBottom: 6,
                    }}
                  >
                    {checklist.name}
                  </Text>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 4,
                    }}
                  >
                    {checklist.checkItems.map((checkItem) => (
                      <View
                        key={checkItem.id}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: 4,
                          alignItems: "start",
                        }}
                      >
                        <View
                          style={{
                            width: 12,
                            height: 12,
                            borderRadius: 4,
                            borderWidth: 1,
                            borderStyle: "solid",
                            flexShrink: 0,
                            backgroundColor:
                              checkItem.state === "complete"
                                ? "#666"
                                : "transparent",
                            borderColor:
                              checkItem.state === "complete" ? "#666" : "#ddd",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {checkItem.state === "complete" && (
                            <Svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="8"
                              height="8"
                              fill="#fff"
                              viewBox="0 0 256 256"
                            >
                              <Path d="M232.49,80.49l-128,128a12,12,0,0,1-17,0l-56-56a12,12,0,1,1,17-17L96,183,215.51,63.51a12,12,0,0,1,17,17Z" />
                            </Svg>
                          )}
                        </View>
                        <Text
                          key={checkItem.id}
                          style={{
                            fontSize: 11,
                            ...(checkItem.state === "complete" && {
                              textDecoration: "line-through",
                            }),
                          }}
                        >
                          {checkItem.name}
                        </Text>
                      </View>
                    ))}
                  </View>
                </View>
              </View>
            ))}
          </>
        )}
      </Page>
    </Document>
  );
}
