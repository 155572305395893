import React from "react";
import Markdown from "react-markdown";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Link,
  Font,
} from "@react-pdf/renderer";
import remarkGfm from "remark-gfm";
import remarkEmoji from "remark-emoji";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 20,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottom: "1px solid #ddd",
    paddingBottom: 8,
    marginBottom: 8,
    fontSize: 10,
    width: "100%",
    color: "#666",
  },
  title: {
    fontSize: 24,
    color: "#000",
    marginBottom: 8,
  },
  section: {
    padding: 8,
    border: "1px solid #ddd",
    borderRadius: 4,
    marginBottom: 8,
    flexDirection: "column",
  },
  section__title: {
    fontSize: 12,
    marginBottom: 4,
  },
  details__row: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 4,
  },
  details__row__label: {
    fontSize: 11,
    color: "#666",
  },
  details__row__value: {
    fontSize: 11,
    color: "#000",
  },
  p: {
    fontSize: 10,
    marginBottom: 4,
  },
  strong: {
    fontFamily: "Helvetica-Bold",
  },
  link: {
    color: "#0079bf",
  },
  hr: {
    borderBottom: "1px solid #ddd",
    marginBottom: 4,
  },
  list: {
    marginBottom: 4,
  },
  listItem: {
    fontSize: 10,
  },
  em: {
    fontFamily: "Helvetica-Oblique",
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderColor: "#ddd",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    fontSize: 10,
  },
  tr: {
    margin: "auto",
    flexDirection: "row",
  },
  th: {
    padding: 4,
    backgroundColor: "#eee",
    borderStyle: "solid",
    borderColor: "#ddd",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  td: {
    width: "10%",
    padding: 4,
    borderStyle: "solid",
    borderColor: "#ddd",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    overflow: "hidden",
  },
});

Font.registerEmojiSource({
  format: "png",
  url: "https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/",
});

const markdownComponents = {
  p: ({ children }) => <Text style={styles.p}>{children}</Text>,
  strong: ({ children }) => <Text style={styles.strong}>{children}</Text>,
  a: ({ children, href }) => (
    <Link style={styles.link} src={href}>
      {children}
    </Link>
  ),
  hr: () => <View style={styles.hr}></View>,
  del: ({ children }) => (
    <Text style={{ textDecoration: "line-through" }}>{children}</Text>
  ),
  em: ({ children }) => <Text style={styles.em}>{children}</Text>,
  h1: ({ children }) => (
    <Text style={{ fontSize: 16, fontWeight: "bold", marginBottom: 4 }}>
      {children}
    </Text>
  ),
  h2: ({ children }) => (
    <Text style={{ fontSize: 14, fontWeight: "bold", marginBottom: 4 }}>
      {children}
    </Text>
  ),
  h3: ({ children }) => (
    <Text style={{ fontSize: 12, fontWeight: "bold", marginBottom: 4 }}>
      {children}
    </Text>
  ),
  h4: ({ children }) => (
    <Text style={{ fontSize: 10, fontWeight: "bold", marginBottom: 4 }}>
      {children}
    </Text>
  ),
  h5: ({ children }) => (
    <Text style={{ fontSize: 8, fontWeight: "bold", marginBottom: 4 }}>
      {children}
    </Text>
  ),
  h6: ({ children }) => (
    <Text style={{ fontSize: 6, fontWeight: "bold", marginBottom: 4 }}>
      {children}
    </Text>
  ),
  ol: ({ children }) => <View style={styles.list}>{children}</View>,
  ul: ({ children }) => <View style={styles.list}>{children}</View>,
  li: ({ children }) => <Text style={styles.listItem}>• &nbsp;{children}</Text>,
  blockquote: ({ children }) => (
    <View
      style={{ borderLeft: "2px solid #eee", paddingLeft: 4, marginBottom: 4 }}
    >
      {children}
    </View>
  ),
  code: ({ children }) => (
    <Text
      style={{
        fontFamily: "Courier",
        fontSize: 9,
        backgroundColor: "#eee",
        padding: 2,
        borderRadius: 2,
        marginBottom: 4,
      }}
    >
      {children}
    </Text>
  ),
};

// Create Document Component
export function List({ board, rows }) {
  const colWidth = 100 / rows[0].length;
  const descriptionIndex = rows[0].findIndex((col) => col === "Description");

  return (
    <Document>
      <Page size="A4" style={styles.page} orientation="landscape">
        <View style={styles.header} fixed>
          <Text>{board.name}</Text>
          <Text>Exported at {new Date().toLocaleString()}</Text>
        </View>

        <View style={styles.table}>
          <View style={styles.tr} fixed>
            {rows[0].map((col) => (
              <View style={{ ...styles.th, width: `${colWidth}%` }}>
                <Text>{col}</Text>
              </View>
            ))}
          </View>
          {rows.slice(1).map((row) => (
            <View style={styles.tr}>
              {row.map((col, index) => (
                <View style={{ ...styles.td, width: `${colWidth}%` }}>
                  {index === descriptionIndex ? (
                    <Markdown
                      remarkPlugins={[
                        [remarkGfm, { singleTilde: false }],
                        remarkEmoji,
                      ]}
                      components={markdownComponents}
                      disallowedElements={["img"]}
                      unwrapDisallowed={true}
                    >
                      {col}
                    </Markdown>
                  ) : (
                    <Text>{col}</Text>
                  )}
                </View>
              ))}
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
}
