import React, { createContext, useContext, useState, useEffect } from "react";

import { Loading } from "../components/loading";
import { posthog } from "posthog-js";

export const TrelloContext = createContext();
function useForceUpdate() {
  const [, setValue] = useState(0);
  return () => setValue((value) => ++value);
}

export function TrelloProvider(props) {
  const [$trello, setTrello] = useState(null);
  const forceUpdate = useForceUpdate();
  const [member, setMember] = useState();

  useEffect(() => {
    // if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    //   setTrello(mockTrelloPowerUpConnector());
    // } else {
    async function initTrelloConnector() {
      const t = window.TrelloPowerUp.iframe({
        // appKey: "6ed16050c4542ff86616d07f3b595b52",
        appKey: "c780210a62a449d50baf4dfebaac67ba",
        appName: "Smart Export",
      });

      t.render(forceUpdate);

      setTrello(t);
      setMember(await t.member("all"));
    }

    if (window.TrelloPowerUp) {
      initTrelloConnector();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (member) {
      try {
        posthog.identify(member.id);
      } catch (e) {}
    }
  }, [member]);

  return !$trello ? (
    <Loading />
  ) : (
    <TrelloContext.Provider value={$trello} {...props} />
  );
}

export function useTrello() {
  return useContext(TrelloContext);
}
