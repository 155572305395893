import React, { useState, useEffect } from "react";
import { useTrello } from "../contexts/TrelloContext";

import { useStyletron } from "baseui";
import { Button } from "baseui/button";
import { ButtonGroup } from "baseui/button-group";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from "baseui/modal";
import { LabelMedium, LabelXSmall } from "baseui/typography";
import { Select } from "baseui/select";
import { StyledLink } from "baseui/link";

import { useLicense } from "../contexts/LicenseContext";
import { InvalidLicenseBox } from "../components/InvalidLicenseBox";
import { parseISO } from "date-fns";
import { downloadBlob } from "../utils/export";
import { patchDocument, PatchType, TextRun } from "docx";

const TEMPLATES = {
  1: { id: 1, label: "Simple" },
};

const DEFAULTS = {
  template: 1,
};

export const getPatches = (fields) => {
  const patches = {};

  for (const field in fields) {
    patches[field] = {
      type: PatchType.PARAGRAPH,
      children: [new TextRun({ text: fields[field] })],
    };
  }

  return patches;
};

export default function CardWordView() {
  const [css, theme] = useStyletron();
  const $trello = useTrello();

  const [isAuthorizationModalOpen, setIsAuthorizationModalOpen] =
    useState(false);

  const [isExporting, setIsExporting] = useState(false);
  const [board, setBoard] = useState();
  const [lists, setLists] = useState();
  const [card, setCard] = useState();

  const [template, setTemplate] = useState(DEFAULTS.template);

  const { valid, expiresAt } = useLicense();

  useEffect(() => {
    async function fetchData() {
      setCard(
        (await $trello.cards("all")).find(
          (card) => card.id === $trello.arg("cardId")
        )
      );
      setBoard(await $trello.board("all"));
      setLists(await $trello.lists("all"));
    }

    if ($trello) {
      fetchData();
    }
  }, [$trello]);

  useEffect(() => {
    if ($trello) {
      async function checkIfAuthorizationIsNeeded() {
        const token = await $trello.getRestApi().getToken();
        if (!token) {
          setIsAuthorizationModalOpen(true);
        }
      }
      checkIfAuthorizationIsNeeded();
    }
  }, [$trello]);

  if (!valid || !expiresAt) {
    return <InvalidLicenseBox />;
  }

  async function exportToWord() {
    setIsExporting(true);

    const template = await fetch("/templates/word/simple.docx");
    const templateBuffer = await template.arrayBuffer();
    const doc = await patchDocument(templateBuffer, {
      patches: {
        ...getPatches({
          boardName: board.name,
          cardName: card.name,
          cardNumber: `#${card.idShort}`,
          listName: lists.find((list) => list.id === card.idList).name,
          dueDate: card.due ? parseISO(card.due).toLocaleString() : "-",
          startDate: card.start ? parseISO(card.start).toLocaleString() : "-",
          labels: card.labels.map((label) => label.name).join(", "),
          members: card.members.map((member) => member.fullName).join(", "),
        }),
        description: {
          type: PatchType.PARAGRAPH,
          children: card.desc
            .split("\n")
            .map((line) => new TextRun({ break: 1, text: line })),
        },
      },
    });

    downloadBlob(`${card.name}.docx`, new Blob([doc]));

    setIsExporting(false);
  }

  return (
    <>
      <div
        className={css({
          paddingLeft: theme.sizing.scale600,
          paddingRight: theme.sizing.scale600,
          paddingBottom: "80px",
          paddingTop: theme.sizing.scale600,
        })}
      >
        <LabelMedium
          $style={{
            marginTop: theme.sizing.scale500,
            marginBottom: theme.sizing.scale100,
          }}
        >
          Template
        </LabelMedium>
        <Select
          options={Object.values(TEMPLATES)}
          size="compact"
          value={[
            {
              id: template,
            },
          ]}
          placeholder="Select a template"
          onChange={(params) =>
            params &&
            params.value &&
            params.value[0] &&
            setTemplate(params.value[0].id)
          }
          overrides={{
            Root: {
              style: {
                marginTop: theme.sizing.scale300,
              },
            },
          }}
          clearable={false}
        />
        <LabelXSmall
          $style={{
            marginTop: theme.sizing.scale300,
            marginBottom: theme.sizing.scale300,
            color: theme.colors.contentSecondary,
            fontWeight: 400,
          }}
        >
          Contact us at{" "}
          <StyledLink target="_blank" href="mailto:support@smart-export.app">
            support@smart-export.app
          </StyledLink>{" "}
          if you need a custom template.
        </LabelXSmall>
        <div
          className={css({
            width: "100%",
            position: "fixed",
            display: "flex",
            flexDirection: "column",
            gap: theme.sizing.scale300,
            boxSizing: "border-box",
            bottom: 0,
            left: 0,
            backgroundColor: theme.colors.background,
            boxShadow: theme.lighting.shadow400,
            paddingTop: theme.sizing.scale400,
            paddingRight: theme.sizing.scale600,
            paddingBottom: theme.sizing.scale400,
            paddingLeft: theme.sizing.scale600,
          })}
        >
          <div
            className={css({
              display: "flex",
              justifyContent: "space-between",
            })}
          >
            <div className={css({ width: "33%" })}>
              {$trello && (
                <Button kind="minimal" onClick={() => $trello.closeModal()}>
                  Cancel
                </Button>
              )}
            </div>
            <div className={css({ width: "33%" })}>
              <ButtonGroup
                overrides={{
                  Root: {
                    style: {
                      justifyContent: "flex-end",
                    },
                  },
                }}
              >
                <Button
                  kind="primary"
                  onClick={() => exportToWord()}
                  overrides={{
                    BaseButton: {
                      style: {
                        backgroundColor: theme.colors.accent,
                        color: theme.colors.contentInversePrimary,
                        ":hover": {
                          backgroundColor: theme.colors.accent500,
                        },
                      },
                    },
                  }}
                  isLoading={isExporting}
                  disabled={!card}
                >
                  {!card ? "Loading data" : "Export to Word"}
                </Button>
              </ButtonGroup>
            </div>
          </div>
        </div>
      </div>
      <Modal
        onClose={() => setIsAuthorizationModalOpen(false)}
        isOpen={isAuthorizationModalOpen}
        overrides={{ Close: { style: { display: "none" } } }}
        animate
        closeable={false}
        autoFocus
      >
        <ModalHeader>Authorization needed</ModalHeader>
        <ModalBody>
          We need you to authorize to be able to use this Power-Up.
        </ModalBody>
        <ModalFooter>
          <ModalButton
            onClick={async () => {
              try {
                await $trello.getRestApi().authorize({ scope: "read" });
                setIsAuthorizationModalOpen(false);
              } catch (error) {}
            }}
          >
            Authorize
          </ModalButton>
        </ModalFooter>
      </Modal>
    </>
  );
}
