import React from "react";

import { H4, Paragraph3 } from "baseui/typography";
import { Notification, KIND } from "baseui/notification";
import { StyledLink } from "baseui/link";
import { Block } from "baseui/block";
import { ArrowRight } from "baseui/icon";
import { Grid, Cell } from "baseui/layout-grid";
import { OfficialLinks } from "../components/OfficialLinks";

export function AboutView() {
  return (
    <Grid gridMargins={0}>
      <Cell span={[6, 4]}>
        <H4 marginTop={0}>{process.env.REACT_APP_PRODUCT_NAME}</H4>
        <OfficialLinks />
      </Cell>
      <Cell span={[6, 4]}>
        <Block paddingTop="36px">
          <Notification kind={KIND.positive}>
            Your license is valid.
          </Notification>
        </Block>
        <Block paddingTop="12px">
          <Paragraph3>Use this tool to manage your licences.</Paragraph3>
          <StyledLink
            href={process.env.REACT_APP_LICENSE_MANAGER_URL}
            target="_blank"
          >
            License Manager <ArrowRight />
          </StyledLink>
        </Block>
      </Cell>
    </Grid>
  );
}
