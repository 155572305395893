import React, { useState, useEffect } from "react";
import { useTrello } from "../contexts/TrelloContext";

import { useStyletron } from "baseui";
import { Checkbox } from "baseui/checkbox";
import { Button } from "baseui/button";
import { StatefulPopover } from "baseui/popover";
import { LabelSmall, LabelXSmall, LabelMedium } from "baseui/typography";
import { List, arrayMove, arrayRemove } from "baseui/dnd-list";
import { Avatar } from "baseui/avatar";
import { ButtonGroup } from "baseui/button-group";
import { TriangleDown } from "baseui/icon";
import { StatefulMenu } from "baseui/menu";
import { Tag } from "baseui/tag";
import { StatefulTabs, Tab } from "baseui/tabs-motion";
import { RadioGroup, Radio } from "baseui/radio";
import { Notification } from "baseui/notification";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from "baseui/modal";
import { Select } from "baseui/select";
import { Input } from "baseui/input";

import { download, downloadBlob, processRows } from "./../utils/export";
import { pdf } from "@react-pdf/renderer";
import * as Xlsx from "xlsx";
import { useLicense } from "../contexts/LicenseContext";
import { InvalidLicenseBox } from "../components/InvalidLicenseBox";
import { AboutView } from "../components/AboutView";
import { format, parseISO } from "date-fns";
import { List as PdfList } from "../templates/pdf/list";
import {
  patchDocument,
  PatchType,
  Table,
  TextRun,
  TableRow,
  TableCell,
  Paragraph,
} from "docx";
import { getPatches } from "./CardWordView";
import { StyledLink } from "baseui/link";

const TRIAL_CARD_LIMIT = 5;

const FIELDS = [
  "id",
  "idShort",
  "name",
  "desc",
  "list",
  "labels",
  "url",
  "members",
  "attachments",
  "dividedAttachments",
  "start",
  "due",
  "createdAt",
  "checklists",
  "checkItemsProgress",
];

const FIELDS_LABELS = {
  id: "ID",
  idShort: "Card number",
  name: "Name",
  desc: "Description",
  list: "List name",
  labels: "Labels",
  url: "URL",
  members: "Members",
  attachments: "Attachments (names and URLs in one column)",
  dividedAttachments: "Attachments (names and URLs seperated into two columns)",
  start: "Start date",
  due: "Due date",
  createdAt: "Creation date",
  checkItemsProgress: "Checklists (items progress)",
  checklists: "Checklists (full data)",
};

const DATE_FORMATS = {
  1: { id: 1, label: "ISO 8601" },
  2: { id: 2, label: "yyyy-MM-dd HH:mm" },
  3: { id: 3, label: "yyyy-MM-dd" },
  4: { id: 4, label: "dd.MM.yyyy HH:mm" },
  5: { id: 5, label: "dd.MM.yyyy" },
  6: { id: 6, label: "MM/dd/yyyy HH:mm" },
  7: { id: 7, label: "MM/dd/yyyy" },
  8: { id: 8, label: "dd/MM/yyyy HH:mm" },
  9: { id: 9, label: "dd/MM/yyyy" },
};

const DEFAULTS = {
  fields: ["list", "id", "name", "desc", "labels", "url"],
  excludedListsIds: [],
  includedMembersIds: [],
  includedLabelsIds: [],
  sheetsSplittingOption: "1",
  dateFormat: 1,
};

async function fetchAmazingFields(boardId, token) {
  const response = await fetch(
    `https://api.amazingpowerups.com/api/data/v1/boards/${boardId}/amazingFields?token=${token}`
  );
  return response.json();
}

async function fetchAmazingFieldsCards(boardId, token) {
  const response = await fetch(
    `https://api.amazingpowerups.com/api/data/v1/boards/${boardId}/cards?token=${token}`
  );
  return response.json();
}

export default function ExportView() {
  const [css, theme] = useStyletron();
  const $trello = useTrello();

  const [isAuthorizationModalOpen, setIsAuthorizationModalOpen] =
    useState(false);
  const [isUpgrading, setIsUpgrading] = useState(false);

  const [board, setBoard] = useState();
  const [lists, setLists] = useState([]);
  const [fields, setFields] = useState(DEFAULTS.fields);
  const [excludedListsIds, setExcludedListsIds] = useState(
    DEFAULTS.excludedListsIds
  );
  const [includedMembersIds, setIncludedMembersIds] = useState(
    DEFAULTS.includedMembersIds
  );
  const [includedLabelsIds, setIncludedLabelsIds] = useState(
    DEFAULTS.includedLabelsIds
  );
  const [sheetsSplittingOption, setSheetsSplittingOption] = useState(
    DEFAULTS.sheetsSplittingOption
  );
  const [dateFormat, setDateFormat] = useState(DEFAULTS.dateFormat);
  const [amazingFieldsApiToken, setAmazingFieldsApiToken] = useState("");
  const [amazingFields, setAmazingFields] = useState([]);

  const [filteredCards, setFitleredCards] = useState([]);

  const [hasLastUsedFiltersBeenApplied, setHasLastUsedFiltersBeenApplied] =
    useState(false);

  const [isExporting, setIsExporting] = useState(false);

  const { valid, expiresAt } = useLicense();

  useEffect(() => {
    if ($trello) {
      async function checkIfAuthorizationIsNeeded() {
        const token = await $trello.getRestApi().getToken();
        if (!token) {
          setIsAuthorizationModalOpen(true);
        }
      }
      checkIfAuthorizationIsNeeded();
    }
  }, [$trello]);

  useEffect(() => {
    if ($trello) {
      async function fetchLists() {
        setLists(await $trello.lists("id", "name"));
      }
      async function fetchBoard() {
        const board = await $trello.board(
          "id",
          "name",
          "labels",
          "customFields",
          "members"
        );
        setBoard(board);
      }
      async function setLastUsedFilters() {
        const lastUsedFilters =
          (await $trello.get("board", "private", `lastUsedFilters`)) || {};

        lastUsedFilters.fields && setFields(lastUsedFilters.fields);
        lastUsedFilters.excludedListsIds &&
          setExcludedListsIds(lastUsedFilters.excludedListsIds);
        lastUsedFilters.includedMembersIds &&
          setIncludedMembersIds(lastUsedFilters.includedMembersIds);
        lastUsedFilters.includedLabelsIds &&
          setIncludedLabelsIds(lastUsedFilters.includedLabelsIds);
        lastUsedFilters.sheetsSplittingOption &&
          setSheetsSplittingOption(lastUsedFilters.sheetsSplittingOption);
        lastUsedFilters.dateFormat && setDateFormat(lastUsedFilters.dateFormat);

        if (
          lastUsedFilters.fields ||
          lastUsedFilters.excludedListsIds ||
          lastUsedFilters.includedMembersIds ||
          lastUsedFilters.includedLabelsIds ||
          lastUsedFilters.sheetsSplittingOption ||
          lastUsedFilters.dateFormat
        ) {
          setHasLastUsedFiltersBeenApplied(true);
        }
      }

      async function fetchAmazingFieldsApiToken() {
        const apiToken = await $trello.get(
          "member",
          "private",
          "amazingFieldsApiToken"
        );
        setAmazingFieldsApiToken(apiToken);
      }

      fetchLists();
      fetchBoard();
      setLastUsedFilters();
      fetchAmazingFieldsApiToken();
    }
  }, [$trello]);

  useEffect(() => {
    async function initAmazingFields() {
      try {
        const amazingFields = await fetchAmazingFields(
          board.id,
          amazingFieldsApiToken
        );
        setAmazingFields(amazingFields.fields);
      } catch (error) {
        console.error("Error fetching Amazing Fields: ", error);
      }
    }

    if (board && amazingFieldsApiToken) {
      initAmazingFields();
    }
  }, [board, amazingFieldsApiToken]);

  useEffect(() => {
    const includeOnlyListId = $trello && $trello.arg("listId");

    if (includeOnlyListId) {
      setExcludedListsIds(
        lists
          .filter((list) => list.id !== includeOnlyListId)
          .map((list) => list.id)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lists]);

  useEffect(() => {
    async function fetchAndFilterCards() {
      setFitleredCards(
        (await $trello.cards("all"))
          .filter((card) => !excludedListsIds.includes(card.idList))
          .filter((card) =>
            includedMembersIds.length > 0
              ? card.members.some((member) =>
                  includedMembersIds.includes(member.id)
                ) ||
                (card.members.length === 0 &&
                  includedMembersIds.includes("NO_MEMBER"))
              : true
          )
          .filter((card) =>
            includedLabelsIds.length > 0
              ? card.labels.some((label) =>
                  includedLabelsIds.includes(label.id)
                ) ||
                (card.labels.length === 0 &&
                  includedLabelsIds.includes("NO_LABEL"))
              : true
          )
      );
    }

    if ($trello) {
      fetchAndFilterCards();
    }
  }, [$trello, excludedListsIds, includedMembersIds, includedLabelsIds]);

  useEffect(() => {
    if (
      sheetsSplittingOption === "2" &&
      excludedListsIds.length === lists.length
    ) {
      setSheetsSplittingOption("1");
    }
  }, [excludedListsIds, lists, sheetsSplittingOption]);

  useEffect(() => {
    if (
      sheetsSplittingOption === "3" &&
      includedMembersIds.length === 1 &&
      includedMembersIds.includes("NO_MEMBER")
    ) {
      setSheetsSplittingOption("1");
    }
  }, [includedMembersIds, sheetsSplittingOption]);

  function resetFilters() {
    setFields(DEFAULTS.fields);
    setExcludedListsIds(DEFAULTS.excludedListsIds);
    setIncludedMembersIds(DEFAULTS.includedMembersIds);
    setIncludedLabelsIds(DEFAULTS.includedLabelsIds);
    setSheetsSplittingOption(DEFAULTS.sheetsSplittingOption);
    setDateFormat(DEFAULTS.dateFormat);

    $trello.set("board", "private", "lastUsedFilters", null);
  }

  function getAllFields() {
    return [
      ...FIELDS,
      ...(board && board.customFields.map((field) => field.id)),
      ...amazingFields.map((field) => field.id),
    ];
  }

  function getFieldLabel(field) {
    if (field === "dividedAttachments") {
      return ["Attachments names", "Attachments URLs"];
    }

    if (field === "attachments") {
      return ["Attachments"];
    }

    if (field in FIELDS_LABELS) {
      return [FIELDS_LABELS[field]];
    }

    const customField = board.customFields.find(
      (customField) => customField.id === field
    );
    if (customField) {
      return [customField.name];
    }

    const amazingField = amazingFields?.find(
      (amazingField) => amazingField.id === field
    );
    if (amazingField) {
      return [amazingField.name];
    }
  }

  async function getRows(extraFilterFunction = () => true, isExcel = true) {
    const headers = fields.map((field) => getFieldLabel(field)).flat();
    let rows = [headers];

    let doubleFilteredCards = filteredCards.filter(extraFilterFunction);

    if (!valid || !expiresAt) {
      doubleFilteredCards = doubleFilteredCards.slice(0, TRIAL_CARD_LIMIT);
    }

    let amazingFieldsCards = [];
    if (fields.some((field) => amazingFields?.some((af) => af.id === field))) {
      try {
        // We need to fetch AF cards
        const response = await fetchAmazingFieldsCards(
          board.id,
          amazingFieldsApiToken
        );
        amazingFieldsCards = response.cards;
      } catch (error) {
        console.error("Error fetching Amazing Fields cards: ", error);
      }
    }

    for (const card of doubleFilteredCards) {
      rows.push(
        (
          await Promise.all(
            fields.map(
              async (field) =>
                await getFieldValue(
                  card,
                  field,
                  false,
                  isExcel,
                  amazingFieldsCards
                )
            )
          )
        ).flat()
      );
    }

    return rows;
  }

  async function getObjects() {
    let objects = [];

    let cards = filteredCards;

    if (!valid || !expiresAt) {
      cards = cards.slice(0, TRIAL_CARD_LIMIT);
    }

    let amazingFieldsCards = [];
    if (fields.some((field) => amazingFields?.some((af) => af.id === field))) {
      try {
        // We need to fetch AF cards
        const response = await fetchAmazingFieldsCards(
          board.id,
          amazingFieldsApiToken
        );
        amazingFieldsCards = response.cards;
      } catch (error) {
        console.error("Error fetching Amazing Fields cards: ", error);
      }
    }

    for (const card of cards) {
      const object = {};

      for (const field of fields) {
        object[field] = (
          await getFieldValue(card, field, true, false, amazingFieldsCards)
        )[0];
      }

      objects.push(object);
    }

    return objects;
  }

  async function getFieldValue(
    card,
    field,
    preserveOriginal = false,
    isExcel = false,
    amazingFieldsCards = []
  ) {
    switch (field) {
      case "list":
        return [lists.find((list) => list.id === card.idList).name];

      case "labels":
        return !preserveOriginal
          ? [card[field].map((label) => label.name || label.color).join(", ")]
          : [card[field]];

      case "members":
        return !preserveOriginal
          ? [card[field].map((label) => label.fullName).join(", ")]
          : [card[field]];

      case "attachments":
        return !preserveOriginal
          ? [
              card[field]
                .map((attachment) => `${attachment.name} - ${attachment.url}`)
                .join("\n"),
            ]
          : [card[field]];

      case "dividedAttachments":
        return !preserveOriginal
          ? [
              card["attachments"]
                .map((attachment) => attachment.name)
                .join("\n"),
              card["attachments"]
                .map((attachment) => attachment.url)
                .join("\n"),
            ]
          : [card["attachments"]];

      case "checklists": {
        if (card.badges.checkItems > 0) {
          try {
            const token = await $trello.getRestApi().getToken();
            const params = new URLSearchParams({
              // key: "6ed16050c4542ff86616d07f3b595b52",
              key: "c780210a62a449d50baf4dfebaac67ba",
              token,
            });
            const result = await fetch(
              `https://api.trello.com/1/cards/${card.id}/checklists?${params}`
            );

            if (preserveOriginal) {
              return [await result.json()];
            }

            let value = "";

            for (const checklist of (await result.json()).sort((a, b) =>
              a.pos > b.pos ? 1 : -1
            )) {
              value += `\n${checklist.name}:\n`;
              for (const item of checklist.checkItems.sort((a, b) =>
                a.pos > b.pos ? 1 : -1
              )) {
                value += "- ";
                if (item.state === "complete") {
                  value += "[DONE] ";
                }
                if (item.due) {
                  let formattedDue = item.due;

                  if (dateFormat > 1) {
                    try {
                      formattedDue = format(
                        parseISO(item.due),
                        DATE_FORMATS[dateFormat].label
                      );
                    } catch (error) {
                      console.error(error);
                    }
                  }

                  value += `[${formattedDue}] `;
                }
                if (item.idMember) {
                  const member = board.members.find(
                    (member) => member.id === item.idMember
                  );
                  if (member) {
                    value += `[${member.fullName}] `;
                  }
                }
                value += `${item.name}\n`;
              }
            }

            return [value.substr(1)];
          } catch (error) {
            console.error(error);
            return [""];
          }
        } else {
          return [""];
        }
      }

      case "start": {
        try {
          let formattedStart = card.start;

          if (formattedStart && !preserveOriginal) {
            if (dateFormat === 1) {
              if (isExcel) {
                formattedStart = parseISO(card.start);
              }
            } else {
              try {
                formattedStart = format(
                  parseISO(card.start),
                  DATE_FORMATS[dateFormat].label
                );
              } catch (error) {
                console.error(error);
              }
            }
          }

          return [formattedStart];
        } catch (error) {
          console.error(error);
          return [""];
        }
      }

      case "checkItemsProgress":
        return [`${card.badges.checkItemsChecked}/${card.badges.checkItems}`];

      case "due":
        let formattedDue = card.due;

        if (formattedDue && !preserveOriginal) {
          if (dateFormat === 1) {
            if (isExcel) {
              formattedDue = parseISO(card.due);
            }
          } else {
            try {
              formattedDue = format(
                parseISO(card.due),
                DATE_FORMATS[dateFormat].label
              );
            } catch (error) {
              console.error(error);
            }
          }
        }

        return [formattedDue];

      case "createdAt":
        const hex = card.id.substring(0, 8);
        const timestamp = parseInt(hex, 16);
        const createdAt = new Date(timestamp * 1000);
        let formattedCreatedAt = createdAt.toISOString();

        if (formattedCreatedAt && !preserveOriginal) {
          if (dateFormat === 1) {
            if (isExcel) {
              formattedCreatedAt = createdAt;
            }
          } else {
            try {
              formattedCreatedAt = format(
                createdAt,
                DATE_FORMATS[dateFormat].label
              );
            } catch (error) {
              console.error(error);
            }
          }
        }

        return [formattedCreatedAt];

      default:
        const customField = board.customFields.find(
          (customField) => customField.id === field
        );

        const amazingField = amazingFields?.find(
          (amazingField) => amazingField.id === field
        );

        if (customField) {
          const customFieldItem = card.customFieldItems.find(
            (customField) => customField.idCustomField === field
          );

          if (preserveOriginal && customFieldItem) {
            return [customFieldItem];
          }

          if (customFieldItem) {
            if (customFieldItem.value) {
              if (customField.type === "number") {
                return [parseFloat(customFieldItem.value["number"])];
              } else if (customField.type === "date") {
                const value = customFieldItem.value["date"];
                let formattedValue = value;

                if (formattedValue && !preserveOriginal) {
                  if (dateFormat === 1) {
                    if (isExcel) {
                      formattedValue = parseISO(value);
                    }
                  } else {
                    try {
                      formattedValue = format(
                        parseISO(value),
                        DATE_FORMATS[dateFormat].label
                      );
                    } catch (error) {
                      console.error(error);
                      return [value];
                    }
                  }
                }

                return [formattedValue];
              } else {
                const value =
                  customFieldItem.value[
                    customField.type === "checkbox" ? "checked" : "text"
                  ];
                return [value];
              }
            } else if (customFieldItem.idValue) {
              const option = customField.options.find(
                (option) => option.id === customFieldItem.idValue
              );

              if (option) {
                const value = Object.values(option.value)[0];

                if (option.type === "number") {
                  return [parseFloat(value)];
                } else {
                  return [value];
                }
              } else {
                return [""];
              }
            } else {
              return [""];
            }
          } else {
            return [""];
          }
        } else if (amazingField) {
          const amazingFieldCard = amazingFieldsCards?.find(
            (amazingFieldCard) => amazingFieldCard.id === card.id
          );

          if (!amazingFieldCard) {
            return [""];
          }

          const amazingFieldItem = amazingFieldCard.amazingFields?.fields?.find(
            (amazingFieldItem) => amazingFieldItem.id === field
          );

          if (
            amazingFieldItem &&
            amazingFieldItem.value !== null &&
            typeof amazingFieldItem.value !== "undefined"
          ) {
            return [
              typeof amazingFieldItem.value.toString === "function"
                ? amazingFieldItem.value.toString()
                : JSON.stringify(amazingFieldItem.value),
            ];
          }

          return [""];
        } else {
          return [card[field]];
        }
    }
  }

  function confirmIfNoCards(exportFunction) {
    return () => {
      if (filteredCards.length === 0) {
        const exportAnyway = window.confirm(
          "None of the cards are going to be exported. Do you wish to continue anyway?"
        );

        exportAnyway && exportFunction();
      } else {
        exportFunction();
      }
    };
  }

  function confirmIfNoLicense(exportFunction) {
    return () => {
      if (!valid || !expiresAt) {
        const exportAnyway = window.confirm(
          "In trial you can export only 5 cards. Do you wish to continue anyway?"
        );

        exportAnyway && exportFunction();
      } else {
        exportFunction();
      }
    };
  }

  async function saveFilters() {
    await $trello.set("board", "private", `lastUsedFilters`, {
      fields,
      excludedListsIds,
      includedLabelsIds,
      includedMembersIds,
      sheetsSplittingOption,
      dateFormat,
    });
  }

  async function exportToXlsx() {
    try {
      await saveFilters();
    } catch (error) {
      console.error(error);
    }
    setIsExporting(true);
    const workbook = Xlsx.utils.book_new();

    switch (sheetsSplittingOption) {
      case "2":
        for (const list of lists.filter(
          (list) => !excludedListsIds.includes(list.id)
        )) {
          const sheet = Xlsx.utils.aoa_to_sheet(
            await getRows((card) => card.idList === list.id),
            { cellDates: dateFormat === 1 }
          );
          try {
            Xlsx.utils.book_append_sheet(
              workbook,
              sheet,
              list.name.replace(/\/|\\|\?|\*|\[|\]/g, "").substr(0, 31)
            );
          } catch (error) {
            console.error(error);
            Xlsx.utils.book_append_sheet(
              workbook,
              sheet,
              list.name.replace(/\/|\\|\?|\*|\[|\]/g, "").substr(0, 29) + "-1"
            );
          }
        }
        break;

      case "3":
        for (const member of board.members.filter((member) =>
          includedMembersIds.length > 0
            ? includedMembersIds.includes(member.id)
            : true
        )) {
          const sheet = Xlsx.utils.aoa_to_sheet(
            await getRows((card) =>
              card.members.some((_member) => _member.id === member.id)
            ),
            { cellDates: dateFormat === 1 }
          );
          Xlsx.utils.book_append_sheet(
            workbook,
            sheet,
            member.fullName.replace(/\/|\\|\?|\*|\[|\]/g, "").substr(0, 31)
          );
        }
        break;

      default:
        const sheet = Xlsx.utils.aoa_to_sheet(await getRows(), {
          cellDates: dateFormat === 1,
        });
        Xlsx.utils.book_append_sheet(workbook, sheet, "Cards");
        break;
    }

    const date = new Date().toISOString().split("T")[0].split("-").join("");

    Xlsx.writeFile(workbook, `${board.name} - ${date}.xlsx`);
    setIsExporting(false);
  }

  async function exportToCsv() {
    saveFilters();
    setIsExporting(true);
    const date = new Date().toISOString().split("T")[0].split("-").join("");

    download(
      `${board.name} - ${date}.csv`,
      "text/csv",
      processRows(await getRows(undefined, false))
    );
    setIsExporting(false);
  }

  async function exportToJson() {
    saveFilters();
    setIsExporting(true);
    const date = new Date().toISOString().split("T")[0].split("-").join("");

    download(
      `${board.name} - ${date}.json`,
      "application/json",
      JSON.stringify(await getObjects())
    );
    setIsExporting(false);
  }

  async function exportToPdf() {
    saveFilters();

    setIsExporting(true);
    const date = new Date().toISOString().split("T")[0].split("-").join("");

    const instance = pdf(
      <PdfList board={board} rows={await getRows(undefined, false)} />
    );

    downloadBlob(`${board.name} - ${date}.pdf`, await instance.toBlob());
    setIsExporting(false);
  }

  async function exportToWord() {
    saveFilters();

    setIsExporting(true);
    const date = new Date().toISOString().split("T")[0].split("-").join("");
    const rows = await getRows(undefined, false);
    const descriptionIndex = rows[0].findIndex((col) => col === "Description");

    const template = await fetch("/templates/word/list.docx");
    const templateBuffer = await template.arrayBuffer();
    const doc = await patchDocument(templateBuffer, {
      patches: {
        ...getPatches({
          boardName: board.name,
        }),
        table: {
          type: PatchType.DOCUMENT,
          children: [
            new Table({
              rows: rows.map(
                (row, index) =>
                  new TableRow({
                    children: row.map(
                      (cell, cindex) =>
                        new TableCell({
                          children: [
                            new Paragraph({
                              ...(index > 0 && cindex === descriptionIndex
                                ? {
                                    children: cell
                                      .split("\n")
                                      .map(
                                        (line) =>
                                          new TextRun({ break: 1, text: line })
                                      ),
                                  }
                                : { text: cell }),
                            }),
                          ],
                        })
                    ),
                    tableHeader: index === 0,
                  })
              ),
            }),
          ],
        },
      },
    });

    downloadBlob(`${board.name} - ${date}.docx`, new Blob([doc]));
    setIsExporting(false);
  }

  if (isUpgrading) {
    return <InvalidLicenseBox onSuccess={() => setIsUpgrading(false)} />;
  }

  return (
    <>
      {hasLastUsedFiltersBeenApplied && (
        <Notification
          overrides={{
            Body: {
              style: {
                margin: 0,
                width: "100%",
                boxSizing: "border-box",
                paddingLeft: theme.sizing.scale600,
                paddingRight: theme.sizing.scale600,
                paddingTop: theme.sizing.scale300,
                paddingBottom: theme.sizing.scale300,
              },
            },
            InnerContainer: {
              style: {
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                //paddingRight: "10px",
              },
            },
            CloseIcon: {
              style: {
                margin: "auto",
              },
            },
          }}
          // closeable
        >
          {({ dismiss }) => (
            <>
              <span>
                Your last used filters for this board has been applied.
              </span>
              <Button
                size="mini"
                onClick={() => {
                  resetFilters();
                  dismiss();
                }}
              >
                Reset
              </Button>
            </>
          )}
        </Notification>
      )}
      <div
        className={css({
          paddingLeft: theme.sizing.scale600,
          paddingRight: theme.sizing.scale600,
          paddingBottom: "80px",
          paddingTop: theme.sizing.scale600,
        })}
      >
        <StatefulTabs>
          <Tab title="Lists">
            <LabelXSmall
              $style={{
                marginTop: 0,
                marginBottom: theme.sizing.scale300,
                color: theme.colors.contentSecondary,
                fontWeight: 400,
              }}
            >
              Select lists to be included in the export.
            </LabelXSmall>
            <div>
              <Button
                size="mini"
                kind="secondary"
                onClick={() => {
                  if (excludedListsIds.length === lists.length) {
                    setExcludedListsIds([]);
                  } else {
                    setExcludedListsIds(lists.map((list) => list.id));
                  }
                }}
                $style={{ marginBottom: theme.sizing.scale300 }}
              >
                {excludedListsIds.length === lists.length
                  ? "Select"
                  : "Unselect"}{" "}
                all
              </Button>
              {lists &&
                lists.map((list) => (
                  <Checkbox
                    key={list.id}
                    onChange={(e) => {
                      if (!e.target.checked) {
                        setExcludedListsIds([...excludedListsIds, list.id]);
                      } else {
                        setExcludedListsIds(
                          excludedListsIds.filter((x) => x !== list.id)
                        );
                      }
                    }}
                    checked={!excludedListsIds.includes(list.id)}
                  >
                    {list.name}
                  </Checkbox>
                ))}
            </div>
          </Tab>
          <Tab title="Fields">
            <LabelXSmall
              $style={{
                marginTop: 0,
                marginBottom: theme.sizing.scale300,
                color: theme.colors.contentSecondary,
                fontWeight: 400,
              }}
            >
              Select fields to be included in the export and arrange them in
              desired order.
            </LabelXSmall>
            <div>
              <StatefulPopover
                content={({ close }) => (
                  <div
                    className={css({
                      padding: "10px",
                      backgroundColor: theme.colors.backgroundPrimary,
                      boxShadow: theme.lighting.shadow400,
                      width: "200px",
                      minHeight: "200px",
                    })}
                  >
                    <LabelSmall $style={{ marginBottom: "10px" }}>
                      Click on the field to add it
                    </LabelSmall>
                    {getAllFields().filter((field) => !fields.includes(field))
                      .length > 0 ? (
                      getAllFields()
                        .filter((field) => !fields.includes(field))
                        .map((field, index) => (
                          <Button
                            key={index}
                            size="mini"
                            kind="minimal"
                            onClick={() => {
                              setFields([...fields, field]);
                              close();
                            }}
                            $style={{
                              display: "block",
                              width: "100%",
                              textAlign: "left",
                            }}
                          >
                            {field === "dividedAttachments" ||
                            field === "attachments"
                              ? FIELDS_LABELS[field]
                              : getFieldLabel(field)}
                            {field === "createdAt" && (
                              <span
                                className={css({
                                  marginLeft: "4px",
                                  padding: "2px 4px",
                                  borderRadius: "4px",
                                  background: theme.colors.accent,
                                  color: "#fff",
                                })}
                              >
                                New!
                              </span>
                            )}
                          </Button>
                        ))
                    ) : (
                      <LabelXSmall>None</LabelXSmall>
                    )}
                  </div>
                )}
              >
                <Button size="mini" kind="secondary">
                  Add field
                </Button>
              </StatefulPopover>
              <StyledLink
                href="https://trello.com/power-ups/60e068efb294647187bbe4f5/amazing-fields?utm_source=add_field&utm_id=smart_export"
                target="_blank"
                $style={{
                  display: "block",
                  marginTop: theme.sizing.scale300,
                  marginBottom: theme.sizing.scale300,
                  color: theme.colors.accent,
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                We support Amazing Fields!
              </StyledLink>
              <List
                items={fields}
                removable
                onChange={({ oldIndex, newIndex }) =>
                  setFields(
                    newIndex === -1
                      ? arrayRemove(fields, oldIndex)
                      : arrayMove(fields, oldIndex, newIndex)
                  )
                }
                overrides={{
                  Item: {
                    style: {
                      paddingTop: "4px",
                      paddingRight: "6px",
                      paddingBottom: "4px",
                      paddingLeft: "6px",
                    },
                  },
                  Label({ children }) {
                    return (
                      <div
                        className={css({
                          flexGrow: 1,
                          ...theme.typography.font200,
                        })}
                      >
                        {children === "dividedAttachments" ||
                        children === "attachments"
                          ? FIELDS_LABELS[children]
                          : getFieldLabel(children)}
                      </div>
                    );
                  },
                }}
              />
            </div>
          </Tab>
          <Tab title="Members">
            <LabelXSmall
              $style={{
                marginTop: 0,
                marginBottom: theme.sizing.scale300,
                color: theme.colors.contentSecondary,
                fontWeight: 400,
              }}
            >
              Select members to be included in the export. If none is selected
              the export includes all cards.
            </LabelXSmall>
            <div>
              <Button
                size="mini"
                kind="secondary"
                onClick={() => {
                  if (includedMembersIds.length === board.members.length + 1) {
                    setIncludedMembersIds([]);
                  } else {
                    setIncludedMembersIds([
                      ...board.members.map((member) => member.id),
                      "NO_MEMBER",
                    ]);
                  }
                }}
                $style={{ marginBottom: theme.sizing.scale300 }}
              >
                {board && includedMembersIds.length === board.members.length + 1
                  ? "Unselect"
                  : "Select"}{" "}
                all
              </Button>
              {board &&
                [...board.members, { id: "NO_MEMBER" }].map((member) => (
                  <Checkbox
                    key={member.id}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setIncludedMembersIds([
                          ...includedMembersIds,
                          member.id,
                        ]);
                      } else {
                        setIncludedMembersIds(
                          includedMembersIds.filter((x) => x !== member.id)
                        );
                      }
                    }}
                    checked={includedMembersIds.includes(member.id)}
                    overrides={{
                      Root: {
                        style: {
                          marginTop: theme.sizing.scale200,
                          marginBottom: theme.sizing.scale200,
                        },
                      },
                    }}
                  >
                    <div
                      className={css({
                        display: "flex",
                        alignItems: "center",
                      })}
                    >
                      {member.id === "NO_MEMBER" ? (
                        "No member"
                      ) : (
                        <>
                          <Avatar
                            name={member.fullName}
                            size={"scale800"}
                            src={member.avatar}
                          />{" "}
                          <LabelMedium
                            $style={{
                              marginLeft: "6px",
                            }}
                          >
                            {member.fullName}
                          </LabelMedium>
                        </>
                      )}
                    </div>
                  </Checkbox>
                ))}
            </div>
          </Tab>
          <Tab title="Labels">
            <LabelXSmall
              $style={{
                marginTop: 0,
                marginBottom: theme.sizing.scale300,
                color: theme.colors.contentSecondary,
                fontWeight: 400,
              }}
            >
              Select labels to be included in the export. If none is selected
              the export includes all cards.
            </LabelXSmall>
            <div>
              <Button
                size="mini"
                kind="secondary"
                onClick={() => {
                  if (includedLabelsIds.length === board.labels.length + 1) {
                    setIncludedLabelsIds([]);
                  } else {
                    setIncludedLabelsIds([
                      ...board.labels.map((member) => member.id),
                      "NO_LABEL",
                    ]);
                  }
                }}
                $style={{ marginBottom: theme.sizing.scale300 }}
              >
                {board && includedLabelsIds.length === board.labels.length + 1
                  ? "Unselect"
                  : "Select"}{" "}
                all
              </Button>
              {board &&
                [...board.labels, { name: "No label", id: "NO_LABEL" }].map(
                  (label) => (
                    <Checkbox
                      key={label.id}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setIncludedLabelsIds([
                            ...includedLabelsIds,
                            label.id,
                          ]);
                        } else {
                          setIncludedLabelsIds(
                            includedLabelsIds.filter((x) => x !== label.id)
                          );
                        }
                      }}
                      checked={includedLabelsIds.includes(label.id)}
                      overrides={{
                        Root: {
                          style: {
                            marginTop: theme.sizing.scale200,
                            marginBottom: theme.sizing.scale200,
                          },
                        },
                      }}
                    >
                      <div
                        className={css({
                          display: "flex",
                          alignItems: "center",
                        })}
                      >
                        {label.id === "NO_LABEL" ? (
                          label.name
                        ) : (
                          <Tag
                            closeable={false}
                            kind="custom"
                            color="gray"
                            variant="solid"
                            size="small"
                            overrides={{
                              Root: {
                                style: {
                                  marginTop: 0,
                                  marginBottom: 0,
                                },
                              },
                            }}
                          >
                            {label.name || label.color}
                          </Tag>
                        )}
                      </div>
                    </Checkbox>
                  )
                )}
            </div>
          </Tab>
          <Tab
            title={
              <>
                Settings
                <span
                  className={css({
                    marginLeft: "4px",
                    padding: "2px 4px",
                    borderRadius: "4px",
                    background: theme.colors.accent,
                    color: "#fff",
                  })}
                >
                  New!
                </span>
              </>
            }
          >
            <LabelMedium
              $style={{
                marginTop: theme.sizing.scale300,
                marginBottom: theme.sizing.scale100,
              }}
            >
              Excel sheets splitting
            </LabelMedium>
            <RadioGroup
              value={sheetsSplittingOption}
              onChange={(e) => setSheetsSplittingOption(e.target.value)}
            >
              <Radio value="1">
                <small>Don't split</small>
              </Radio>
              <Radio
                value="2"
                disabled={excludedListsIds.length === lists.length}
              >
                <small>List per sheet</small>
              </Radio>
              <Radio
                value="3"
                disabled={
                  includedMembersIds.length === 1 &&
                  includedMembersIds.includes("NO_MEMBER")
                }
              >
                <small>Member per sheet</small>
              </Radio>
            </RadioGroup>
            <LabelMedium
              $style={{
                marginTop: theme.sizing.scale500,
                marginBottom: theme.sizing.scale100,
              }}
            >
              Date format
            </LabelMedium>
            <Select
              options={Object.values(DATE_FORMATS)}
              size="compact"
              value={[
                {
                  id: dateFormat,
                },
              ]}
              placeholder="Select date format"
              onChange={(params) =>
                params &&
                params.value &&
                params.value[0] &&
                setDateFormat(params.value[0].id)
              }
              overrides={{
                Root: {
                  style: {
                    marginTop: theme.sizing.scale300,
                  },
                },
              }}
              clearable={false}
            />
            <LabelMedium
              $style={{
                marginTop: theme.sizing.scale500,
                marginBottom: theme.sizing.scale100,
              }}
            >
              Amazing Fields API Token
              <small
                className={css({
                  marginLeft: "4px",
                  padding: "2px 4px",
                  borderRadius: "4px",
                  background: theme.colors.accent,
                  color: "#fff",
                })}
              >
                New!
              </small>
            </LabelMedium>
            <Input
              value={amazingFieldsApiToken}
              size="compact"
              onChange={(e) => {
                setAmazingFieldsApiToken(e.target.value);
                $trello?.set(
                  "member",
                  "private",
                  "amazingFieldsApiToken",
                  e.target.value
                );
              }}
              overrides={{
                Root: {
                  style: {
                    marginTop: theme.sizing.scale300,
                  },
                },
              }}
            />
            <LabelXSmall
              $style={{
                marginTop: theme.sizing.scale300,
                marginBottom: theme.sizing.scale300,
                color: theme.colors.contentSecondary,
                fontWeight: 400,
              }}
            >
              To be able to export Amazing Fields you need to provide your API
              Token. Learn more{" "}
              <StyledLink
                href="https://docs.amazingpowerups.com/article/40-using-amazing-fields-api?utm_source=settings&utm_id=smart_export#:~:text=Trello%20API%20documentation.-,Allocate%20API%20Token,-API%20access%20is"
                target="_blank"
              >
                here
              </StyledLink>
              .
            </LabelXSmall>
          </Tab>
          <Tab
            title="About"
            overrides={{
              Tab: {
                style: {
                  marginLeft: "auto",
                },
              },
            }}
          >
            <div
              className={css({
                marginTop: theme.sizing.scale300,
                marginBottom: theme.sizing.scale100,
              })}
            >
              <AboutView />
            </div>
          </Tab>
        </StatefulTabs>

        <div
          className={css({
            width: "100%",
            position: "fixed",
            display: "flex",
            flexDirection: "column",
            gap: theme.sizing.scale300,
            boxSizing: "border-box",
            bottom: 0,
            left: 0,
            backgroundColor: theme.colors.background,
            boxShadow: theme.lighting.shadow400,
            paddingTop: theme.sizing.scale400,
            paddingRight: theme.sizing.scale600,
            paddingBottom: theme.sizing.scale400,
            paddingLeft: theme.sizing.scale600,
          })}
        >
          <div
            className={css({
              display: "flex",
              justifyContent: "space-between",
            })}
          >
            <div className={css({ width: "33%" })}>
              {$trello && (
                <Button kind="minimal" onClick={() => $trello.closeModal()}>
                  Cancel
                </Button>
              )}
            </div>
            <div
              className={css({
                width: "33%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              })}
            >
              Cards being exported:
              <Tag
                closeable={false}
                variant="solid"
                kind={filteredCards.length > 0 ? "positive" : "negative"}
              >
                {filteredCards.length}
              </Tag>
            </div>
            <div className={css({ width: "33%" })}>
              <ButtonGroup
                overrides={{
                  Root: {
                    style: {
                      justifyContent: "flex-end",
                    },
                  },
                }}
              >
                <Button
                  kind="primary"
                  onClick={confirmIfNoLicense(confirmIfNoCards(exportToXlsx))}
                  overrides={{
                    BaseButton: {
                      style: {
                        backgroundColor: theme.colors.accent,
                        color: theme.colors.contentInversePrimary,
                        ":hover": {
                          backgroundColor: theme.colors.accent500,
                        },
                      },
                    },
                  }}
                  isLoading={isExporting}
                >
                  Export to Excel
                </Button>
                <StatefulPopover
                  content={({ close }) => (
                    <StatefulMenu
                      items={[
                        { label: "Export to CSV" },
                        { label: "Export to PDF" },
                        { label: "Export to JSON" },
                        { label: "Export to Word" },
                      ]}
                      onItemSelect={({ item }) => {
                        if (!isExporting) {
                          if (item.label === "Export to JSON") {
                            confirmIfNoLicense(
                              confirmIfNoCards(exportToJson)
                            )();
                          } else if (item.label === "Export to PDF") {
                            confirmIfNoLicense(confirmIfNoCards(exportToPdf))();
                          } else if (item.label === "Export to Word") {
                            confirmIfNoLicense(
                              confirmIfNoCards(exportToWord)
                            )();
                          } else {
                            confirmIfNoLicense(confirmIfNoCards(exportToCsv))();
                          }
                        }
                        close();
                      }}
                    />
                  )}
                >
                  <Button disabled={!valid} kind="secondary">
                    <TriangleDown />
                  </Button>
                </StatefulPopover>
              </ButtonGroup>
            </div>
          </div>
          {(!valid || !expiresAt) && (
            <Notification
              kind="warning"
              overrides={{
                Body: {
                  style: {
                    margin: 0,
                    width: "100%",
                    boxSizing: "border-box",
                    paddingLeft: theme.sizing.scale600,
                    paddingRight: theme.sizing.scale600,
                    paddingTop: theme.sizing.scale300,
                    paddingBottom: theme.sizing.scale300,
                  },
                },
                InnerContainer: {
                  style: {
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    //paddingRight: "10px",
                  },
                },
                CloseIcon: {
                  style: {
                    margin: "auto",
                  },
                },
              }}
              // closeable
            >
              {() => (
                <>
                  <span>
                    You are currently using <strong>trial</strong> version.
                  </span>
                  <Button
                    size="mini"
                    onClick={() => {
                      setIsUpgrading(true);
                    }}
                  >
                    Upgrade
                  </Button>
                </>
              )}
            </Notification>
          )}
        </div>
      </div>
      <Modal
        onClose={() => setIsAuthorizationModalOpen(false)}
        isOpen={isAuthorizationModalOpen}
        overrides={{ Close: { style: { display: "none" } } }}
        animate
        closeable={false}
        autoFocus
      >
        <ModalHeader>Authorization needed</ModalHeader>
        <ModalBody>
          We need you to authorize to be able to use this Power-Up.
        </ModalBody>
        <ModalFooter>
          <ModalButton
            onClick={async () => {
              try {
                await $trello.getRestApi().authorize({ scope: "read" });
                setIsAuthorizationModalOpen(false);
              } catch (error) {}
            }}
          >
            Authorize
          </ModalButton>
        </ModalFooter>
      </Modal>
    </>
  );
}
