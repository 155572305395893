import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import { LightTheme, BaseProvider } from "baseui";
import { ToasterContainer } from "baseui/toast";
import { TrelloProvider } from "./contexts/TrelloContext";
import ExportView from "./containers/ExportView";
import CardPdfView from "./containers/CardPdfView";
import AuthorizeView from "./containers/AuthorizeView";
import CardWordView from "./containers/CardWordView";

import { LicenseProvider } from "./contexts/LicenseContext";

import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";

const engine = new Styletron();

// Check that PostHog is client-side (used to handle Next.js SSR)
if (typeof window !== "undefined") {
  posthog.init(process.env.REACT_APP_POSTHOG_KEY, {
    api_host: process.env.REACT_APP_POSTHOG_HOST || "https://app.posthog.com",
    opt_in_site_apps: true,
    persistence: "memory",
    // Enable debug mode in development
    loaded: (posthog) => {
      if (process.env.NODE_ENV === "development") posthog.debug();
    },
  });
}

export default function App() {
  return (
    <PostHogProvider client={posthog}>
      <StyletronProvider value={engine}>
        <BaseProvider theme={LightTheme}>
          <ToasterContainer>
            <TrelloProvider>
              <LicenseProvider>
                <Router>
                  <Switch>
                    <Route path="/export">
                      <ExportView />
                    </Route>
                    <Route path="/pdf">
                      <CardPdfView />
                    </Route>
                    <Route path="/word">
                      <CardWordView />
                    </Route>
                    <Route path="/authorize">
                      <AuthorizeView />
                    </Route>
                  </Switch>
                </Router>
              </LicenseProvider>
            </TrelloProvider>
          </ToasterContainer>
        </BaseProvider>
      </StyletronProvider>
    </PostHogProvider>
  );
}
